import React from 'react'
import StpdBannerComponent from 'stpd-ad-component'
import config from 'SRC/config/config.yaml'

import { SocServices } from '../../../shared/components'

export const Default = () => {
  return (
    <aside className='sidebar'>
      <div style={{ width: 300, height: 250 }}>
        <StpdBannerComponent
          refreshOnUrlChange={true}
          adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.sidebar.default.adUnit}`}
          size={config.banners.sidebar.default.sizes}
          divId={config.banners.sidebar.default.slotId}
        />
      </div>
      <SocServices />
    </aside>
  )
}

Default.displayName = 'DefaultSidebar'
