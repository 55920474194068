import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import StpdBannerComponent from 'stpd-ad-component'
import config from 'SRC/config/config.yaml'

export const SideBanners = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  if (windowWidth > 1800) {
    return (
      <React.Fragment>
        <div className='banner-300-768-left' key='banner-300-768-left'>
          <div style={{ width: 300, height: 768 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.left.adUnit}`}
              size={config.banners.background.left.sizes}
              divId={config.banners.background.left.slotId}
            />
          </div>
        </div>

        <div className='banner-300-768-right' key='banner-300-768-right'>
          <div style={{ width: 300, height: 768 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.right.adUnit}`}
              size={config.banners.background.right.sizes}
              divId={config.banners.background.right.slotId}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (windowWidth > 1520) {
    return (
      <React.Fragment>
        <div className='banner-160-600-left' key='banner-160-600-left'>
          <div style={{ width: 160, height: 600 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.leftMedium.adUnit}`}
              size={config.banners.background.leftMedium.sizes}
              divId={config.banners.background.leftMedium.slotId}
            />
          </div>
        </div>

        <div className='banner-160-600-right' key='banner-160-600-right'>
          <div style={{ width: 160, height: 600 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.rightMedium.adUnit}`}
              size={config.banners.background.rightMedium.sizes}
              divId={config.banners.background.rightMedium.slotId}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (windowWidth > 1400) {
    return (
      <React.Fragment>
        <div className='banner-120-600-left' key='banner-120-600-left'>
          <div style={{ width: 120, height: 600 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.leftSmall.adUnit}`}
              size={config.banners.background.leftSmall.sizes}
              divId={config.banners.background.leftSmall.slotId}
            />
          </div>
        </div>

        <div className='banner-120-600-right' key='banner-120-600-right'>
          <div style={{ width: 120, height: 600 }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.background.rightSmall.adUnit}`}
              size={config.banners.background.rightSmall.sizes}
              divId={config.banners.background.rightSmall.slotId}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  return null
}
